/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Component Imports */

import { Skeleton } from "@mui/material";
import BusinessProfileLoadingCard from "./BusinessProfileLoadingCard";
import PropertyLoadingCardMini from "./PropertyLoadingCardMiniTwo";

const CoverBannerSkeleton = styled(Skeleton)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "space-between",
	width: "100%",
	height: "11rem",
	borderRadius: "1rem",
	padding: "0rem 0rem 0rem 0rem",
	margin: "0rem 0rem 0rem 0rem",
}));

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "space-between",
	width: "100%",
	gap: "0.5rem",
	padding: "0rem 0rem 0rem 0rem",
	margin: "1.25rem 0rem 1.25rem 0rem",
}));

const CardsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-around",
	alignItems: "space-between",
	flexWrap: "wrap",
	width: "100%",
	gap: "0.5rem",
}));

const CategoryCard = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
}));

const BannerContainer = styled(Skeleton)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	borderRadius: "1rem",
	// margin: "0rem 0rem 1rem 0rem",
}));

const ServiceContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "space-between",
	width: "100%",
	gap: "0.5rem",
	paddingTop: "1rem",
}));

const ContainerCards = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	gap: "1rem",
	flexWrap: "nowrap",
	scrollBehavior: "smooth",
	overflowX: "auto",
	scrollSnapType: "x mandatory",
	width: "100%",

	"& > *": {
		minWidth: "180px",
		scrollSnapAlign: "start",
	},

	"&::-webkit-scrollbar": {
		display: "none",
	},
	scrollbarWidth: "none",
	"-ms-overflow-style": "none",
}));

const HomePageMobileLoading = () => {
	let propertyloadingcards = [1, 2, 3, 4, 5];
	return (
		<React.Fragment>
			<div
				style={{
					padding: "4rem 0rem 0rem 0rem",
				}}
			>
				<CoverBannerSkeleton
					animation="wave"
					variant="rectangular"
					width="100%"
				/>

				<Container>
					<Skeleton
						animation="wave"
						variant="text"
						width="70%"
						height="2rem"
					/>

					<CardsContainer>
						<CategoryCard>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width="100%"
								height="4rem"
								style={{ borderRadius: "0.5rem" }}
							/>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width="100%"
								height="4rem"
								style={{ borderRadius: "0.5rem" }}
							/>
						</CategoryCard>
						<CategoryCard>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width="100%"
								height="4rem"
								style={{ borderRadius: "0.5rem" }}
							/>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width="100%"
								height="4rem"
								style={{ borderRadius: "0.5rem" }}
							/>
						</CategoryCard>
					</CardsContainer>
				</Container>

				<BannerContainer
					animation="wave"
					variant="rectangular"
					width="100%"
					height="5rem"
				/>

				<ServiceContainer>
					<Skeleton
						animation="wave"
						variant="text"
						width="70%"
						height="2rem"
					/>
					<CardsContainer>
						<CategoryCard>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width="5.5rem"
								height="5.5rem"
								style={{ borderRadius: "0.5rem" }}
							/>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width="5.5rem"
								height="5.5rem"
								style={{ borderRadius: "0.5rem" }}
							/>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width="5.5rem"
								height="5.5rem"
								style={{ borderRadius: "0.5rem" }}
							/>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width="5.5rem"
								height="5.5rem"
								style={{ borderRadius: "0.5rem" }}
							/>
						</CategoryCard>
					</CardsContainer>
				</ServiceContainer>
			</div>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					paddingTop: "1rem",
					justifyContent: "space-between",
					paddingBottom: "0.5rem",
				}}
			>
				<Skeleton
					animation="wave"
					variant="text"
					width="70%"
					height="2rem"
				/>
				<Skeleton
					animation="wave"
					variant="text"
					width="20%"
					height="2rem"
				/>
			</div>
			<ContainerCards>
				{propertyloadingcards.map((id: number) => {
					return <PropertyLoadingCardMini key={id} />;
				})}
			</ContainerCards>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					paddingTop: "1rem",
					justifyContent: "space-between",
					paddingBottom: "0.5rem",
				}}
			>
				<Skeleton
					animation="wave"
					variant="text"
					width="70%"
					height="2rem"
				/>
				<Skeleton
					animation="wave"
					variant="text"
					width="20%"
					height="2rem"
				/>
			</div>
			<ContainerCards
				sx={{
					"& > *": {
						minWidth: "90px",
						scrollSnapAlign: "start",
					},
					marginBottom: "2rem",
				}}
			>
				{propertyloadingcards.map((id: number) => {
					return <BusinessProfileLoadingCard key={id} />;
				})}
			</ContainerCards>
		</React.Fragment>
	);
};

export default HomePageMobileLoading;
