import { updateSearchFilterActiveOptionsThunk, updateSearchFilterSelectedOptionsThunk } from "../redux-magic/store";
import { SearchFilterId } from "../redux-magic/sub-interfaces/search-types";
import {
	updateSearchFilterAppliedThunk,
	updateSearchFilterIsAccordionOpenThunk,
	updateSearchFilterVisibilityThunk,
} from "../redux-magic/thunks";

import {
	agricultural_options_active,
	commercial_options_active,
	industrial_options_active,
	residential_options_active,
	warehousing_options_active,
} from "../lib/data/search-filters/_e_property_type";
import {
	cleaning_and_maintenance_options_active,
	construction_options_active,
	food_options_active,
	grooming_and_other_personal_care_options_active,
	home_and_appliance_repair_options_active,
	home_utilities_options_active,
	hospitality_options_active,
	interiors_and_decor_options_active,
	legal_and_finance_options_active,
	liaisoning_options_active,
	other_options_active,
	social_service_options_active,
	transport_options_active,
} from "./data/search-filters/_x_service_subtype";
/*

* FILTER_CATEGORIES: This object is used to categorize the filters based on their type.

*/
const FILTER_CATEGORIES = {
	property_category: ["property_category"],
	property_type: ["property_type"],
	business: ["business_profile_type"],
	service: ["service_category", "service_type", "service_subtype"],
	text: [
		"search_type",
		"transaction_type",
		// "plot_dimensions", uncomment later whovevr is editing
		"property_status",
		"unit_facing",
		"land_facing",
		"furnishing",
		"amenities",
		"listed_by",
		"bedrooms_variant_2",
		"bathrooms_variant_2",
		"balconies_variant_2",
	],
	range: [
		"budget",
		// "security_deposit", uncomment later whovevr is editing
		// "bedrooms_variant_2",
		"area",
		// "bathrooms",
		// "balconies", uncomment later whovevr is editing
		"seats",
		"possession_date",
		"available_from",
		// "floors", uncomment later whovevr is editing
	],
	location: ["location"],
};

/*

	* updateFilters: This function is used to update the filters based on the key and options provided. dispatcing key and options tat comes from the filterHandlers function.

*/

const updateFilters = (key: string, options: any, dispatch: any) => {
	if (key.includes(key as SearchFilterId) && options.length > 0) {
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: key as SearchFilterId,
				newOptions: options,
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: key as SearchFilterId,
				isApplied: true,
			}),
		);
		// if (key.includes("property_category") && options[0].id !== "plot") {
		dispatch(
			updateSearchFilterVisibilityThunk({
				searchFilterId: key as SearchFilterId,
				isVisible: true,
			}),
		);
		// }
		dispatch(
			updateSearchFilterIsAccordionOpenThunk({
				searchFilterId: key as SearchFilterId,
				isAccordionOpen: true,
			}),
		);
		/*

		* service hack for now

		*/
		if (key === "search_type" && options[0].value === "services" && !key.includes("service_type")) {
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "service_type", isVisible: true }));
			dispatch(
				updateSearchFilterIsAccordionOpenThunk({
					searchFilterId: "service_type",
					isAccordionOpen: true,
				}),
			);
		}
		if (key === "search_type" && options[0].value === "properties" && !key.includes("property_category")) {
			dispatch(updateSearchFilterVisibilityThunk({ searchFilterId: "property_category", isVisible: true }));
			dispatch(
				updateSearchFilterIsAccordionOpenThunk({
					searchFilterId: "property_category",
					isAccordionOpen: true,
				}),
			);
		}
	} else {
		console.error(`Invalid searchFilterId: ${key}`);
	}
};

/*

	* formatTitle: This function is used to format the title of the filters. It takes the value as input and returns the formatted title. using it only for service coz format is different for services.

*/

const formatTitle = (value: string) => {
	return value
		.split("_")
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(" ");
};

/*

* filterHandlers: This object is used to handle the filters based on their type. calling the above update filters function and crteating an object with id, title and value. whihc is then passed to the updateFilters function.

*/

const filterHandlers = {
	text: (key: string, value: string, dispatch: any) => {
		/*
		 * search_filters_state selected_options can have multiple options.
		 * So, we filter it in the routerURLConfig function where the query and key are generated.
		 * We add a comma and pass it down. If value has a comma, then we split the string
		 * and map over it to get the formatted value.
		 */

		const selected_options = value.split(",").map((val) => val.trim());

		if (key.includes("bedrooms_variant_2")) {
			updateFilters(
				key,
				selected_options.map((val) => ({
					id: `${val}_bhk`,
					title: val === "5" ? `${val}+ BHK` : `${val} BHK`,
					value: val,
				})),
				dispatch,
			);
		} else if (key.includes("bathrooms_variant_2")) {
			updateFilters(
				key,
				selected_options.map((val) => ({
					id: `${val}_bathroom`,
					title: val === "5" ? `${val}+ Bathrooms` : val === "1" ? `$${val} bathroom` : `${val} Bathrooms`,
					value: val,
				})),
				dispatch,
			);
		} else if (key.includes("balconies_variant_2")) {
			updateFilters(
				key,
				selected_options.map((val) => ({
					id: `${val}_balcony`,
					title: val === "5" ? `${val}+ Balconies` : `${val} Balconies`,
					value: val,
				})),
				dispatch,
			);
		} else {
			updateFilters(
				key,
				selected_options.map((val) => ({
					id: val,
					title:
						key === "search_type" && val === "business_profiles"
							? "Business Profiles"
							: val.replace("-", " ").charAt(0).toUpperCase() + val.replace("-", " ").slice(1),
					value: val,
				})),
				dispatch,
			);
		}
	},
	range: (key: string, value: string, dispatch: any) => {
		const [min, max] = value.split("-").map(Number);
		// key === "bedrooms"
		// 	? updateFilters(
		// 			key,
		// 			[
		// 				{
		// 					id: key,
		// 					title: key.charAt(0).toUpperCase() + key.slice(1),
		// 					value: {
		// 						min: min,
		// 						max: max,
		// 						unlock_max: max >= 4 ? true : false,
		// 					},
		// 				},
		// 			],
		// 			dispatch,
		// 		)
		// 	:

		key === "budget"
			? updateFilters(
					key,
					[
						{
							id: key,
							title: key.charAt(0).toUpperCase() + key.slice(1),
							value: {
								min: min,
								max: max,
								unlock_max: max === 0 ? true : false,
							},
						},
					],
					dispatch,
				)
			: updateFilters(
					key,
					[
						{
							id: key,
							title: key.charAt(0).toUpperCase() + key.slice(1),
							value: {
								min: min,
								max: max,
								unlock_max: false,
							},
						},
					],
					dispatch,
				);
	},
	location: (key: string, value: string, dispatch: any) => {
		const [title, lat, lng] = value.split(",");
		if (!title || isNaN(parseFloat(lat)) || isNaN(parseFloat(lng))) {
			console.error("Invalid location format");
			return;
		}
		updateFilters(
			key,
			[
				{
					id: "location",
					title: "Location",
					value: {
						title,
						coordinates: [parseFloat(lat), parseFloat(lng)],
					},
				},
			],
			dispatch,
		);
	},
	default: (key: string, value: string, dispatch: any) => {
		updateFilters(
			key,
			[
				/*

				* Format is different so the conditons
				*/
				FILTER_CATEGORIES.business.includes(key)
					? {
							id: value,
							title: value.charAt(0).toUpperCase() + value.slice(1),
							value: value,
						}
					: FILTER_CATEGORIES.property_category.includes(key)
						? {
								id: value,
								title: value.charAt(0).toUpperCase() + value.slice(1),
								value: value,
							}
						: FILTER_CATEGORIES.property_type.includes(key)
							? {
									id: value.charAt(0).toLowerCase() + value.slice(1),
									title: value,
									value: value,
								}
							: FILTER_CATEGORIES.service.includes(key)
								? {
										id: value,
										title: formatTitle(value),
										value: value,
									}
								: null,
			],
			dispatch,
		);
	},
};

/*

	* RouterQuerySearchFilters: This function is used to handle the filters based on their type. calling the above filterHandlers function and passing the key, value and dispatch as arguments.

*/

const RouterQuerySearchFilters = (key: string, value: string, dispatch: any) => {
	/*

	* if key included in Filter categories then call the respective filterHandlers function.

	*/
	if (FILTER_CATEGORIES.location.includes(key)) {
		filterHandlers.location(key, value, dispatch);
	} else if (FILTER_CATEGORIES.text.includes(key)) {
		filterHandlers.text(key, value, dispatch);
	} else if (FILTER_CATEGORIES.range.includes(key)) {
		filterHandlers.range(key, value, dispatch);
	} else if (
		[
			...FILTER_CATEGORIES.property_category,
			...FILTER_CATEGORIES.property_type,
			...FILTER_CATEGORIES.business,
			...FILTER_CATEGORIES.service,
			...FILTER_CATEGORIES.text,
		].includes(key)
	) {
		filterHandlers.default(key, value, dispatch);
	}

	if (key.includes("property_category")) {
		const propertyTypeOptions =
			value === "residential"
				? residential_options_active
				: value === "commercial"
					? commercial_options_active
					: value === "agricultural"
						? agricultural_options_active
						: value === "warehousing"
							? warehousing_options_active
							: value === "industrial"
								? industrial_options_active
								: residential_options_active;

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: "property_type",
				newOptions: propertyTypeOptions,
			}),
		);
	}
	if (key.includes("service_type")) {
		const serviceSubTypeOptions =
			value === "construction"
				? construction_options_active
				: value === "interiors_and_decor"
					? interiors_and_decor_options_active
					: value === "cleaning_and_maintenance"
						? cleaning_and_maintenance_options_active
						: value === "home_utilities"
							? home_utilities_options_active
							: value === "home_and_appliance_repair"
								? home_and_appliance_repair_options_active
								: value === "legal_and_finance"
									? legal_and_finance_options_active
									: value === "liaisoning"
										? liaisoning_options_active
										: value === "transport"
											? transport_options_active
											: value === "grooming_and_other_personal_care"
												? grooming_and_other_personal_care_options_active
												: value === "social_service"
													? social_service_options_active
													: value === "food"
														? food_options_active
														: value === "hospitality"
															? hospitality_options_active
															: value === "other"
																? other_options_active
																: construction_options_active;
		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: "service_subtype",
				newOptions: serviceSubTypeOptions,
			}),
		);
	}
};

export default RouterQuerySearchFilters;
