/*

? First, let's import the required components.

*/
import { styled, useMediaQuery } from "@mui/system";
import React from "react";
// import Searchbar from "../search/Searchbar";
// import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import {
	Snackbar,
	SnackbarOrigin,
	// Snackbar, SnackbarContent,
	Typography,
	useTheme,
} from "@mui/material";
/*

? Next let's import all the UI sub-components we intend to use on this page.

*/
import { useRouter } from "next/router";
import StateInterface from "../../../redux-magic/state-interface";
import Search from "./SearchComponent/Search";

// const Search = dynamic(() => import("./SearchComponent/Search").then((mod) => mod.default));
// import SearchMobile from "./SearchMobileComponent/SearchMobile";

// const OnboardingModal = dynamic(() =>
// 	import("../../modals/OnboardingModal/OnboardingModal").then((mod) => mod.default),
// );
// import Searchbar from "../search/Searchbar";

/*

? Next, let's import the select change event signature.

*/

/*

& Let's define a custom attribute for our container called index. This will help the layout container organise our component correctly.

*/

type IndexAttribute = {
	index?: number;
};

/*

& Now let's style all the components we intend to use on this page.

*/

const Container = styled("div")<IndexAttribute>(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	width: "100%",
	background: "#ffe9d9",
	borderRadius: "0px 0px 64px 64px",
	[theme.breakpoints.down("sm")]: {
		// background: "none",
		display: "none",
	},
	/* SM breakpoint */
	[theme.breakpoints.only("sm")]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewLightMode.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 3rem 0rem 3rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// height: "35vh",
	},
	/*ipad Mini */
	[theme.breakpoints.only("smPlus")]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 3rem 0rem 3rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// height: "35vh",
	},
	/* ipad Air*/
	[theme.breakpoints.between(819, 821)]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 3rem 0rem 3rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		height: "30vh",
	},
	[theme.breakpoints.only("sm822")]: {
		// backgroundImage:
		// theme.palette.mode === "dark"
		// 	? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 	: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 3rem 0rem 3rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// height: "30vh",
	},
	/* zenfold */
	[theme.breakpoints.between(852, 854)]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 3rem 0rem 3rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		height: "32vh",
	},
	[theme.breakpoints.only("sm860")]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 3rem 0rem 3rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// height: "32vh",
	},
	/*Galaxy fold landscape */
	[theme.breakpoints.between(881, 883)]: {
		padding: "0rem 3rem 0rem 3rem",
		height: "40vh",
	},
	/* Surface Pro 7 */
	[theme.breakpoints.between(911, 913)]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 3rem 0rem 3rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		height: "29vh",
	},
	[theme.breakpoints.only("sm910")]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 3rem 0rem 3rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// height: "30vh",
	},

	/* MD breakpoint */
	[theme.breakpoints.only("md")]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 4rem 0rem 4rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// height: "50vh",
	},

	/* iPad Air */
	[theme.breakpoints.between(1179, 1181)]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 4rem 0rem 4rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		height: "45vh",
	},
	/*Real me Pad 2 */
	[theme.breakpoints.between(1199, 1201)]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 4rem 0rem 4rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		height: "22vh",
	},
	// [theme.breakpoints.down("md1190")]: {
	// 	backgroundImage:
	// 		theme.palette.mode === "dark"
	// 			? "url(images/illustrations/index/NewDarkModeV2.svg)"
	// 			: "url(images/illustrations/index/NewLightModeV2.svg)",
	// 	padding: "0rem 4rem 0rem 4rem",
	// 	backgroundSize: "cover",
	// 	backgroundRepeat: "no-repeat",
	// 	backgroundPosition: "bottom",
	// 	height: "50vh",
	// },
	// 1920x1080 at 150% zoom
	[theme.breakpoints.between(1280, 1281)]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 4rem 0rem 4rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// zIndex: 0,
		height: "50vh",
	},
	// /* LG breakpoint */
	[theme.breakpoints.only("lg")]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 4rem 0rem 4rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// zIndex: 0,
		// height: "55vh",
	},
	/* LGPLUS breakpoint */
	[theme.breakpoints.only("lgPlus")]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 5rem 0rem 5rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// zIndex: 0,
		height: "50vh",
	},
	/* XL breakpoint */
	[theme.breakpoints.only("xl")]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 23rem 0rem 23rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// zIndex: 0,
		// height: "40vh",
	},
	/* XXL breakpoint */
	[theme.breakpoints.only("xxl")]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 36rem 0rem 36rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// zIndex: 0,
		// height: "33vh",
	},
	/* XXXL breakpoint */
	[theme.breakpoints.only("xxxl")]: {
		// backgroundImage:
		// 	theme.palette.mode === "dark"
		// 		? "url(images/illustrations/index/NewDarkModeV2.svg)"
		// 		: "url(images/illustrations/index/NewLightModeV2.svg)",
		padding: "0rem 72rem 0rem 72rem",
		// backgroundSize: "cover",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "bottom",
		// zIndex: 0,
		// height: "25vh",
	},
}));

// const SnackbarContainer = styled("div")(({ theme }) => ({
// 	display: "flex",
// 	flexDirection: "column",
// 	alignItems: "center",
// 	justifyContent: "center",
// 	width: "100%",
// 	/* SM breakpoint */
// 	[theme.breakpoints.only("sm")]: {
// 		padding: "0rem 3rem 0rem 3rem",
// 		backgroundSize: "cover",
// 		backgroundRepeat: "no-repeat",
// 		backgroundPosition: "bottom",
// 	},
// 	/* MD breakpoint */
// 	[theme.breakpoints.only("md")]: {
// 		padding: "0rem 4rem 0rem 4rem",
// 		backgroundSize: "cover",
// 		backgroundRepeat: "no-repeat",
// 		backgroundPosition: "bottom",
// 	},
// 	/* LG breakpoint */
// 	[theme.breakpoints.only("lg")]: {
// 		position: "absolute",
// 		zIndex: 1,
// 		padding: "0rem 4rem 0rem 4rem",
// 	},
// 	/* 1080p 125% breakpoint*/
// 	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
// 		position: "absolute",
// 		zIndex: 1,
// 		padding: "0rem 4rem 0rem 4rem",
// 	},
// 	/* LGPLUS breakpoint */
// 	[theme.breakpoints.up("lgPlus")]: {
// 		position: "absolute",
// 		zIndex: 1,
// 		padding: "0rem 5rem 0rem 5rem",
// 	},
// 	/* XL breakpoint */
// 	[theme.breakpoints.up("xl")]: {
// 		position: "absolute",
// 		zIndex: 1,
// 		padding: "0rem 3rem 0rem 3rem",
// 	},

// 	/* XXL breakpoint */
// 	[theme.breakpoints.up("xxl")]: {
// 		position: "absolute",
// 		zIndex: 1,
// 		padding: "0rem 36rem 0rem 36rem",
// 	},
// 	/* XXXL breakpoint */
// 	[theme.breakpoints.up("xxxl")]: {
// 		position: "absolute",
// 		zIndex: 1,
// 		padding: "0rem 72rem 0rem 72rem",
// 	},
// }));

const TypographyHeading = styled(Typography)(({ theme }) => ({
	display: "flex",
	marginBottom: "1.5rem",
	fontSize: "1.75rem",
	fontWeight: "400",
	marginLeft: "0.5rem",
	color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
	[theme.breakpoints.between("sm", "smPlus")]: {
		display: "flex",
		marginTop: "1.5rem",
		marginBottom: "1.5rem",
		fontSize: "1.75rem",
		fontWeight: "400",
	},
	[theme.breakpoints.only("smPlus")]: {
		display: "flex",
		marginTop: "2.5rem",
		marginBottom: "1.5rem",
		fontSize: "1.75rem",
		fontWeight: "400",
	},
	[theme.breakpoints.between(822, 851)]: {
		display: "flex",
		marginTop: "2.5rem",
		marginBottom: "1.5rem",
		fontSize: "1.75rem",
		fontWeight: "400",
	},
	/* zenfold */
	[theme.breakpoints.between(852, 854)]: {
		display: "flex",
		marginTop: "3.5rem",
		marginBottom: "2rem",
		fontSize: "1.75rem",
		fontWeight: "400",
	},
	[theme.breakpoints.between(854, 910)]: {
		display: "flex",
		marginTop: "3.5rem",
		marginBottom: "2rem",
		fontSize: "1.75rem",
		fontWeight: "400",
	},

	[theme.breakpoints.between(911, 913)]: {
		display: "flex",
		marginTop: "2.5rem",
		marginBottom: "2rem",
		fontSize: "1.75rem",
		fontWeight: "400",
	},
	[theme.breakpoints.between(914, 958)]: {
		display: "flex",
		marginTop: "3.5rem",
		marginBottom: "2rem",
		fontSize: "1.75rem",
		fontWeight: "400",
	},
	/* iPad Pro */
	[theme.breakpoints.between(1023, 1025)]: {
		display: "flex",
		marginTop: "1.5rem",
		marginBottom: "2rem",
		fontSize: "1.75rem",
		fontWeight: "400",
	},
	/*Real me Pad 2 */
	[theme.breakpoints.between(1199, 1201)]: {
		display: "flex",
		marginTop: "3.5rem",
		marginBottom: "2rem",
		fontSize: "1.75rem",
		fontWeight: "400",
	},
	[theme.breakpoints.only("lg")]: {
		display: "flex",
		marginTop: "2rem",
		marginBottom: "2rem",
		fontSize: "1.75rem",
		fontWeight: "400",
	},
	[theme.breakpoints.between(theme.breakpoints.values.lg + 148, theme.breakpoints.values.lg + 152)]: {
		marginTop: "0rem",
	},
	[theme.breakpoints.only("lgPlus")]: {
		display: "flex",
		marginTop: "3.5rem",
		marginBottom: "2.5rem",
		fontSize: "1.75rem",
		fontWeight: "400",
	},
	[theme.breakpoints.only("xl")]: {
		display: "flex",
		marginTop: "3rem",
		marginBottom: "3rem",
		fontSize: "1.75rem",
		fontWeight: "400",
	},
	[theme.breakpoints.only("xxl")]: {
		display: "flex",
		marginTop: "3rem",
		marginBottom: "3rem",
		fontSize: "1.75rem",
		fontWeight: "400",
	},
}));

/*

* The Magic Component

*/

const MagicComponent = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	opacity: 1,
	zIndex: 15,
}));

const SnackbarDiv = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	gap: "8px",
}));
interface State extends SnackbarOrigin {
	open: boolean;
}
/*

& Next, let's create the component.

*/

const HomeHeader = ({
	index,
	dispatch,
	// session,
	// onBoardingDetails,
	// onBoardingTitle,
	// new_business_profile_url,
	search_filter_state,
	searchanimationsettings,
	// search_filters_metrices,
}: {
	index: number;
	// session: any;
	dispatch: Function;
	// onBoardingDetails: StateInterface["on_boarding_details"];
	// onBoardingTitle: StateInterface["on_boarding_title"];
	// new_business_profile_url: StateInterface["new_business_profile_url"];
	search_filter_state: StateInterface["search_filters_state"];
	searchanimationsettings: StateInterface["search_animation_settings"];
	// search_filters_metrices: StateInterface["search_filter_metrices"];
}) => {
	const theme = useTheme();
	const router = useRouter();

	const [animationComplete, setAnimationComplete] = React.useState(false);

	React.useEffect(() => {
		if (searchanimationsettings.StartAnimate) {
			setAnimationComplete(true);
		} else {
			setAnimationComplete(false);
		}
	}, [searchanimationsettings.StartAnimate]);

	const isIPadPro = useMediaQuery("(min-height: 1366px)");

	const [state, setState] = React.useState<State>({
		open: false,
		vertical: "top",
		horizontal: "center",
	});
	const { vertical, horizontal, open } = state;

	const handleClose = () => {
		setState({ ...state, open: false });
	};

	return (
		<React.Fragment>
			<Snackbar
				anchorOrigin={{ vertical, horizontal }}
				open={open}
				onClose={handleClose}
				message={
					<SnackbarDiv>
						<WarningAmberOutlinedIcon sx={{ color: "#5F2B01" }} />
						{router.locale === "ar-AE"
							? "يرجى إدخال الموقع"
							: router.locale === "kn-IN"
								? "ದಯವಿಟ್ಟು ಸ್ಥಳವನ್ನು ನಮೂದಿಸಿ"
								: "Please enter a location"}
					</SnackbarDiv>
				}
				key={`${vertical}-${horizontal}`}
				sx={{ "& .MuiSnackbarContent-root": { backgroundColor: "#FDF0E5", color: "#5F2B01" } }}
			/>

			{!animationComplete && !searchanimationsettings.StartAnimate ? (
				<Container
					index={index}
					sx={{
						"@keyframes CustomHeight": {
							"0%": {
								opacity: 1,
							},
							"25%": {
								opacity: 0.75,
							},
							"50%": {
								opacity: 0.5,
							},
							"75%": {
								opacity: 0.25,
							},
							"100%": {
								opacity: 0,
							},
						},
						direction: router.locale === "ar-AE" ? "rtl" : "ltr",
						animation: searchanimationsettings.StartAnimate ? "CustomHeight 0.25s linear 1" : "none",
						height: animationComplete ? "15vh" : "40vh",
						opacity: searchanimationsettings.StartAnimate ? 0 : 1,
						display: animationComplete ? "none" : "flex",
						paddingTop: animationComplete ? "4.5rem" : "0rem",
						// [theme.breakpoints.down("sm")]: { height: "19vh" },
						[theme.breakpoints.down("sm")]: { height: "30vh" },
						/*1920* 1080 150 %*/
						[theme.breakpoints.between(theme.breakpoints.values.lgPlus + 80, theme.breakpoints.values.lgPlus + 83)]: {
							height: "50vh",
						},
						/*1920 125% (1920/1.25 = 1536) */
						[theme.breakpoints.between(theme.breakpoints.values.lgPlus + 168, theme.breakpoints.values.lgPlus + 171)]: {
							height: "50vh",
						},

						/* iPad Pro */
						[theme.breakpoints.between(1023, 1025)]: {
							padding: "0rem 4rem 0rem 4rem",
							height: isIPadPro ? "30vh" : "62vh",
						},
					}}
				>
					<MagicComponent
						sx={{
							[theme.breakpoints.down("sm")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "50px",
								width: "82.5%",
							},
							[theme.breakpoints.down("smPlus")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "60px",
								width: "84.5%",
							},
							[theme.breakpoints.down("sm822")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "70px",
								width: "86.5%",
							},
							[theme.breakpoints.down("sm860")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "80px",
								width: "87.5%",
							},
							/* Asus ZenBook Fold */
							[theme.breakpoints.down("sm910")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "80px",
								width: "90%",
							},
							/*z fold landscape */
							[theme.breakpoints.down("md")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "120px",
								width: "88%",
							},
							[theme.breakpoints.down("md1190")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "110px",
								width: "89.5%",
							},
							/*Real me Pad 2 */
							[theme.breakpoints.down("md1220")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "100px",
								width: "90%",
							},
							[theme.breakpoints.down("lg")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "80px",
								width: "90%",
							},
							[theme.breakpoints.down(theme.breakpoints.values.lgPlus + 3)]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "90px",
								width: "90%",
							},
							[theme.breakpoints.down("lgPlus")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "80px",
								width: "90%",
							},
							[theme.breakpoints.down(theme.breakpoints.values.lgPlus + 171)]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "60px",
								width: "90%",
							},
							[theme.breakpoints.down("xl")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "60px",
								width: "90%",
							},
							[theme.breakpoints.only("xl")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "80px",
								width: "61.69%",
							},
							[theme.breakpoints.only("xxl")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "100px",
								width: "55%",
							},
							[theme.breakpoints.only("xxxl")]: {
								display: "flex",
								position: searchanimationsettings?.HomeAccordion ? "fixed" : "absolute",
								top: "125px",
								width: "40%",
							},
						}}
					>
						<TypographyHeading>
							{router.locale === "ar-AE"
								? "عزّز عملية البحث عن العقارات الخاصة بك مع بيغرو!"
								: router.locale === "kn-IN"
									? "ಬೀಗ್ರು ಮೂಲಕ ನಿಮ್ಮ ಆಸ್ತಿ ಹುಡುಕಾಟವನ್ನು ಟರ್ಬೋಚಾರ್ಜ್ ಮಾಡಿ"
									: "Turbocharge your property hunt with Beegru"}
						</TypographyHeading>

						<Search
							search_filter_state={search_filter_state}
							dispatch={dispatch}
							searchanimationsettings={searchanimationsettings}
							setState={setState}
						/>
					</MagicComponent>

					{/* {isMobile ? (
						<SearchMobile
							search_filter_state={search_filter_state}
							dispatch={dispatch}
							searchanimationsettings={searchanimationsettings}
							search_filters_metrices={search_filters_metrices}
						/>
					) : null} */}
					{/* {session && session.user && session.user.dbUser && session.user.dbUser.onboarding_complete === false ? (
						<OnboardingModal
							session={session}
							dispatch={dispatch}
							onBoardingDetails={onBoardingDetails}
							onBoardingTitle={onBoardingTitle}
							displayProgress={false}
							new_business_profile_url={new_business_profile_url}
						/>
					) : null} */}
				</Container>
			) : null}
		</React.Fragment>
	);
};

export default HomeHeader;
{
	/* <Container index={index}>
				<Searchbar
					index={0}
					session={session}
					theme={theme}
					dispatch={dispatch}
					lookingFor={lookingFor}
					isSearching={is_searching}
					updateReduxState={true}
					updateQueryString={false}
					enableSearchButtonHits={true}
					alwaysShowFilterButton={false}
					alwaysHideFilterButton={true}
					numberSystem={"international"}
					filtersDialogStateFromSearchPage={false}
					onChangeFiltersDialogState={false}
					searchFiltersAccordionsOrder={searchFiltersAccordionsOrder}
					includeAllResultsOption={false}
					homeLocation={homeLocation}
					homePage
				/>

			</Container> */
}
